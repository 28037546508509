import { Controller } from "@hotwired/stimulus";
import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import SortableTree, {
  changeNodeAtPath, getFlatDataFromTree, removeNodeAtPath, getNodeAtPath
} from "@nosferatu500/react-sortable-tree";

class Tree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      searchFocusIndex: undefined,
      searchFoundCount: 0,
      treeData: props.structure,
      sourceItemsData: props.sourceItems,
      matchedItems: [],
      valid: true
    };
  }

  setState(state) {
    super.setState(
      state,
      () => {
        if (this.state.valid) {
          this.props.onChangeCallback(this.state.treeData);
        }
      }
    );
  }

  nodeTitleField(node, path) {
    if (node.leaf) {
      return null;
    }

    return (
      <input
        value={node.title}
        onChange={event => {
          const input = event.target;
          const title = input.value;

          const parentNode = getNodeAtPath({
            path: path.slice(-2, -1),
            treeData: this.state.treeData,
            getNodeKey: ({ treeIndex }) => treeIndex,
            ignoreCollapsed: false
          }).node;

          const isDuplicate = parentNode.children.some(item => !item.leaf && item.title === title);

          if (isDuplicate) {
            input.className="error";
          } else {
            input.className = input.className.replace("error", "");
          }

          this.setState(state => ({
            valid: !isDuplicate,
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
              newNode: { ...node, title },
            })
          }));
        }}
      />
    );
  }

  nodeDeleteButton(node, path) {
    return (
      <span
        className="btn btn-sm bg-danger text-white"
        onClick={() =>
          this.setState(state => ({
            treeData: removeNodeAtPath({
              treeData: state.treeData,
              path,
              getNodeKey: ({ treeIndex }) => treeIndex,
            }),
          }))
        }>
        <i className="bi bi-trash"></i>
      </span>
    );
  }

  structureNodeProperties({ node, path }) {
    return {
      title: this.nodeTitleField(node, path),
      buttons: [this.nodeDeleteButton(node, path)],
      className: node.leaf ? "file" : "folder"
    };
  }

  listNodeProperties({ node }) {
    const isNodeSelected =
      getFlatDataFromTree({
        treeData: this.state.treeData,
        getNodeKey: ({ treeIndex }) => treeIndex,
        ignoreCollapsed: false
      }).filter(({ node }) => node.id)
        .map(({ node }) => node.id)
        .includes(node.id);

    return {
      className: `file ${isNodeSelected ? "selected" : ''}`.trim()
    };
  }

  canDropFileToAFolder({ node, nextParent, prevPath, nextPath }) {
    const fileExistanceFilter = file => file.title === node.title && (node.leaf && file.id === node.id);

    if (nextParent) {
      const fileAlreadyInFolder = nextParent?.children.filter(fileExistanceFilter).length > 1;

      return !fileAlreadyInFolder;
    } else {
      const isReordering = nextPath.length === prevPath.filter(e => e).length;
      const fileAlreadyInRoot =
        this.state.treeData.some(
          file => file.title === node.title && (node.leaf && file.id === node.id) && !isReordering);

      return !fileAlreadyInRoot;
    }
  }

  createNewFolder() {
    const defaultFolderName = "New Folder";
    let newFolderName = defaultFolderName;

    for (let i = 1; this.state.treeData.some(item => !item.leaf && item.title === newFolderName); i++) {
      newFolderName = `${defaultFolderName} (${i})`;
    }

    this.setState(state => ({
      treeData: state.treeData.concat({
        title: newFolderName
      })
    }));
  }

  nodeMatchesSearchQuery({ node, searchQuery }) {
    if (!searchQuery) {
      return false;
    }

    return node.title.toLowerCase().includes(searchQuery.toLowerCase());
  }

  selectNextMatch = () => {
    this.setState((state) => ({
      searchFocusIndex:
        state.searchFocusIndex != null
          ? (state.searchFocusIndex + 1) % state.searchFoundCount
          : 0
    }));
  };

  selectPrevMatch = () => {
    this.setState((state) => ({
      searchFocusIndex:
        state.searchFocusIndex != null
          ? (state.searchFoundCount + state.searchFocusIndex - 1) % state.searchFoundCount
          : state.searchFoundCount - 1,
    }));
  };

  clearSearch = () => {
    this.setState(() => ({
      searchString: ''
    }));
  };

  highlightMatch = ({ node, searchQuery }) => {
    const parts = node.title.split(new RegExp(`(${searchQuery})`, 'gi'));

    const isFocused = this.state.matchedItems[this.state.searchFocusIndex].node.id === node.id;

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchQuery.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: isFocused ? '#E47825' : '#0080FF' }}>{part}</span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </span>
    );
  };

  nodeProperties = (rowInfo) => {
    const { searchString } = this.state;

    const isSearchMatch = this.nodeMatchesSearchQuery({ node: rowInfo.node, searchQuery: searchString });
    const listProps = this.listNodeProperties(rowInfo);
    const highlightProps = {
      title: isSearchMatch
        ? this.highlightMatch({ node: rowInfo.node, searchQuery: searchString })
        : rowInfo.node.title,
      isSearchMatch: isSearchMatch
    };

    const combinedClassName = `${listProps.className || ''} ${highlightProps.className || ''}`.trim();

    return {
      ...listProps,
      ...highlightProps,
      className: combinedClassName,
    };
  };

  searchFinishCallback = (matches) => {
    this.setState({
      matchedItems: matches,
      searchFoundCount: matches.length,
      searchFocusIndex: matches.length > 0 ? this.state.searchFocusIndex % matches.length : 0
    });
  };

  render() {
    const {
      searchString,
      searchFocusIndex,
      searchFoundCount,
      sourceItemsData
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="react-sortable-tree with-visible-pads">
            <span
              className="btn btn-sm bg-success text-white mb-3"
              onClick={this.createNewFolder.bind(this)}>
              <i className="bi bi-plus" />
              New folder
            </span>

            <SortableTree
              treeData={this.state.treeData}
              onChange={treeData => this.setState({ treeData })}
              canNodeHaveChildren={item => !item.leaf}
              canDrop={this.canDropFileToAFolder.bind(this) }
              dndType={"item"}
              generateNodeProps={this.structureNodeProperties.bind(this)}
              rowHeight={48}
              {...this.props.options}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="react-sortable-tree">
            <h4 className="mb-2">{this.props.options.sourceItemsHeader || "List"}</h4>
            <form className="search-form"
              onSubmit={event => event.preventDefault()}
            >
              <input
                id="find-box"
                type="text"
                placeholder="Search..."
                className="form-control"
                value={searchString}
                onChange={event => this.setState({ searchString: event.target.value })}
              />

              <span className="search-stats ms-2">
                <button
                  type="button"
                  className="btn btn-sm clear-search text-white"
                  onClick={this.clearSearch}>
                    &#215;
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-primary text-white me-2"
                  disabled={!searchFoundCount}
                  onClick={this.selectPrevMatch}
                >
                  &lt;
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary text-white me-2"
                  disabled={!searchFoundCount}
                  onClick={this.selectNextMatch}
                >
                  &gt;
                </button>

                <span>
                  {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                  &nbsp;/&nbsp;
                  {searchFoundCount || 0}
                </span>
              </span>
            </form>
            <SortableTree
              className="mt-3"
              treeData={sourceItemsData}
              onChange={() => {}}
              searchMethod={this.nodeMatchesSearchQuery}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={this.searchFinishCallback}
              generateNodeProps={this.nodeProperties.bind(this)}
              onlyExpandSearchedNodes
              getNodeKey={item => item.id}
              dndType={"item"}
              shouldCopyOnOutsideDrop
              canNodeHaveChildren={false}
              canDrop={false}
              maxDepth={1}
              rowHeight={48}
              {...this.props.options}
            />
          </div>
        </div>
      </div>
    );
  }
}

Tree.propTypes = {
  structure: PropTypes.array,
  sourceItems: PropTypes.array,
  options: PropTypes.object,
  onChangeCallback: PropTypes.func
};

export default class extends Controller {
  static values = {
    structure: Array,
    sourceItems: Array,
    options: Object
  };

  initialize() {
    this.initSortableTree();
  }

  onChangeCallback(updatedValue) {
    const structureInput = this.element.querySelector("input[type='hidden'].structure");
    const associationInput = this.element.querySelector("select");

    const selectedItems = getFlatDataFromTree({
      treeData: updatedValue,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false
    }).filter(({ node }) => node.id)
      .map(({ node }) => node.id);

    structureInput.value = JSON.stringify(updatedValue);

    Array
      .from(associationInput.options)
      .forEach(option => option.selected = selectedItems.includes(option.value));
  }

  initSortableTree() {
    ReactDOM.render(
      <Tree
        structure={this.structureValue}
        sourceItems={this.sourceItemsValue}
        onChangeCallback={this.onChangeCallback.bind(this)}
        options={this.optionsValue} />,
      this.element.querySelector('.structure-tree')
    );
  }
}
