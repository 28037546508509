import Flatpickr from "stimulus-flatpickr";

export default class extends Flatpickr {
  connect() {
    this.config = {
      altInput: true,
      allowInput: true
    };

    super.connect();
  }
}
