import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", function() {
  Array
    .from(document.querySelectorAll("a[data-remote='true']"))
    .forEach(linkNode => {
      linkNode.dataset.url = linkNode.href;
      linkNode.href = "javascript:void(0);";
    });

  Rails.href = function(element) {
    return element.dataset.url || element.href;
  };
});
