import { Toast, Popover, Tooltip } from "bootstrap";

const initializeBootstrapElements = () => {
  Array
    .from(document.querySelectorAll(".toast:not(.show)"))
    .forEach(toastNode => new Toast(toastNode).show());

  Array
    .from(document.querySelectorAll(".dismissable-popover"))
    .forEach(popoverNode => new Popover(popoverNode, {
      html: true
    }));

  Array
    .from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    .forEach(tooltipNode => new Tooltip(tooltipNode, {
      placement: 'top',
      html: true
    }));
};

["turbolinks:load", "shown.bs.modal"].forEach(
  event => document.addEventListener(event, initializeBootstrapElements)
);

document.addEventListener("hide.bs.toast", (event) => event.target.remove());
