import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets =  ["reference", "dependent"];
  static values = {
    url: String,
    param: String
  };

  async change() {
    this.dependentTarget.disabled = true;
    this.dependentTarget.options.length = 0;

    if (!this.referenceTarget.selectedOptions[0]?.value) {
      this.triggerChangeEventOnTarget();
      return;
    }

    await this.buildNewOptions();
    this.triggerChangeEventOnTarget();
  }

  async buildNewOptions() {
    const response = await this.fetchOptions;

    response.data.map(
      (element, index) =>
        this.dependentTarget[index] = new Option(element.name, element.id, element.is_default, element.is_default)
    );

    if (this.dependentTarget.options.length > 0) {
      this.dependentTarget.disabled = false;
    }
  }

  triggerChangeEventOnTarget() {
    this.dependentTarget.dispatchEvent(new Event("change"));
  }

  get params() {
    const params = new URLSearchParams();
    params.append(this.paramValue, this.referenceTarget.selectedOptions[0].value);
    return params;
  }

  get requestOptions() {
    return {
      responseKind: "json", contentType: "application/json"
    };
  }

  get fetchOptions() {
    return get(`${this.urlValue}?${this.params}`, this.requestOptions).then(response => response.json);
  }
}
