import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets =  ["reference", "content"];
  static values = {
    url: String
  };

  async change() {
    if (!this.selectedValue) {
      this.contentTarget.value = null;
      return;
    }

    await this.populateTargetWithContent();
  }

  async populateTargetWithContent() {
    const response = await this.fetchContent;

    this.contentTarget.value = JSON.stringify(response.data.config, null, 2);
    this.contentTarget.rows = this.contentTarget.value.split("\n").length;
  }

  get selectedValue() {
    return this.referenceTarget.selectedOptions[0]?.value;
  }

  get prepareUrl() {
    return this.urlValue.replace(/:id$/, this.selectedValue);
  }

  get requestOptions() {
    return {
      responseKind: "json", contentType: "application/json"
    };
  }

  get fetchContent() {
    return get(this.prepareUrl, this.requestOptions).then(response => response.json);
  }
}
