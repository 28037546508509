import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

export default class extends Controller {
  initialize() {
    this.reactSelect = document.createElement("div");
    this.select.parentNode.insertBefore(this.reactSelect, this.select);
    this.initReactSelect();
  }

  get select() {
    return this.element.querySelector("select");
  }

  get options() {
    return Array.from(this.select.options).filter(x => x.value);
  }

  get defaultValue() {
    const selectedOptions = Array.from(this.select.selectedOptions).filter(x => x.value);

    if (selectedOptions.length === 0) {
      return null;
    }

    return selectedOptions;
  }

  selectOnChange() {
    this.renderReactElement();
  }

  onChange(value) {
    if (value === null) {
      this.select.value = '';
    } else if (!Array.isArray(value)) {
      this.select.value = value?.value;
    } else {
      const selectedItems = value.map(opt => opt.value);

      Array
        .from(this.select.options)
        .forEach(option => option.selected = selectedItems.includes(option.value));
    }

    this.select.dispatchEvent(new Event("change"));
  }

  initReactSelect() {
    this.select.style.display = "none";
    this.select.onchange = this.selectOnChange.bind(this);
    this.renderReactElement();
  }

  renderReactElement() {
    const key = this.options.flatMap(e => e.value).join();

    ReactDOM.render(
      <Select
        options={this.options}
        key={key}
        baseInput={this.select}
        defaultValue={this.defaultValue}
        onChange={this.onChange.bind(this)}
        isMulti={this.select.getAttribute("multiple")}
        isDisabled={this.select.disabled}
        classNamePrefix="react-select"
        className="react-select-container"
        closeMenuOnSelect={!this.select.getAttribute("multiple")}
        isClearable={true}
        placeholder={this.select.getAttribute('placeholder')} />,
      this.reactSelect
    );
  }
}
