import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["aiPatchFields", "resourceTypeDropdown", "includedInAiPatch", "aiPatchPromptInstructions"];

  connect() {
    this.toggleAiPatchFields();
  }

  toggleAiPatchFields() {
    const selectedOption = this.resourceTypeDropdownTarget.selectedIndex;

    const resourceType = this.resourceTypeDropdownTarget[selectedOption].dataset.resourceType;

    if (resourceType === "sound") {
      this.aiPatchFieldsTarget.hidden = false;
    } else {
      this.aiPatchFieldsTarget.hidden = true;
      this.includedInAiPatchTarget.checked = false;
      if (this.hasaiPatchPromptInstructionsTarget) {
        this.aiPatchPromptInstructionsTarget.value = '';
      }
    }
  }
}
