import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import "@popperjs/core";
import "bootstrap";
import "initializers/bootstrap";
import "initializers/rails";

Rails.start();
Turbolinks.start();

window.bootstrap = require("bootstrap");

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);

application.debug = true;
application.load(definitionsFromContext(context));

window.Stimulus = application;
